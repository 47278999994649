import React, { useState } from 'react'
import Login from '../Assets/Login.jpg'
// import bg4 from '../Assets/bg4.jpg'
import axios from 'axios'

const NewLogin = () => {
    const initialValues={username:"",password:""}
    const [formValues,setFormValues] =useState(initialValues)
    const[loginStatus,setLoginStatus]=useState('')
  
    const handleChange =(e) => {
      const {name,value} = e.target
      setFormValues({...formValues,[name]:value});
      console.log(formValues.username)
    }
  
    const FormLogin=(e) => {
      console.log(formValues.username)
      e.preventDefault();
      axios.post("https://mern.digitalaisoft.com/login",{
        logindata:formValues
  
      }).then((response) =>{
        console.log(response);
        if(response.data.length>0)
          {
            let utype=response.data[0].utype
            setLoginStatus('')
            localStorage.setItem('user',formValues.username)
            localStorage.setItem('log',utype)
            if(utype==="user")
              {
                window.location = 'http://myreact.digitalaisoft.com/userhome';
              }
  
              if(utype==="admin")
                {
                  window.location = "http://myreact.digitalaisoft.com/adminhome";
                }
          }
  
          else
          {
            setLoginStatus('Sorry..! Invalid Username or Password')
          }
      })
      .catch(error => {
        console.log(error)
      })
    }
  return (
    <div>
      {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1500, height:700,backgroundSize:'cover'}}> */}
      <div className='container-fluid m-5 '>
        <div className='row'>
          <div className='col-lg-2'></div>

          <div className='col-lg-6 m-5 shadow border border-1 border-dark p-5'>
            <div className='row'>
              <div className='col-lg-5'>
                <form onSubmit={FormLogin}>
                <h2>Login Form</h2>

                <div className='mt-4'>
                  <input className='form-control mt-4 border border-dark' type='email' name='username' value={formValues.username}  onChange={handleChange} placeholder='Email'/>
                </div>

                <div className='mt-3'>
                  <input className='form-control mt-4 border border-dark' type='password' name='password'  value={formValues.password} onChange={handleChange} placeholder='Password'/>
                </div>

                <div className='mt-4'>
                  <button className='btn btn-primary text-center' type='submit' value='login'>Login</button>
                </div>
                </form>
                <p>{loginStatus}</p>
                <p><a href={`/forgotpass/`} className='nav-link'>Forgot Password?</a></p>
                <div className='mt-4'>
                  <p>New Here?<button className='btn btn-primary ml-3'><a href='/reg' className='nav-link'>Signup</a></button></p>
                </div>
              </div>

              <div className='col-lg-4 p-2'>
                 <img src={Login}  width={400} height={350} alt='Not found'/>
              </div>
            </div>
          </div>

          <div className='col-lg-4'></div>

        </div>
      </div>
      </div>
    // </div>
  )
}

export default NewLogin

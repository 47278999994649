import React from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import pay from '../Assets/pay.jpg'

let uid=localStorage.getItem('user');
const Paybill = () => {
    const { id } = useParams();
    const {price} = useParams();
    console.log(id)
    console.log(price)

    const paymentHandler = async (e) => {
        e.preventDefault();

        var options={
            key:"rzp_test_Kpgch0iW4DKfuN",
            key_secret:"7XuA7C57k32FnmI4tYIjbDqJ",
            amount:price*100,
            currency:"INR",
            name:"FINETECH",
            description:"For Testing Purpose",
            handler:function(response){
                //alert(response.razorpay_payment_id)
                axios.post(`https://mern.digitalaisoft.com/paybill/${id}`,{
                    price:price,
                    payment_id:response.rozorpay_payment_id,
                    uid:uid
                }).then((response) => {
                    console.log(response);
                    alert("Payment has been done successfully")
                    window.location="http://myreact.digitalaisoft.com/myorders"
                })
            },
            profile:{
                name:"Shahid",
                email:"shahidcretio@gamil.com",
                contact:"7676792597",
            },

            notes:{
                address:"Razorpay Corporate Office"
            },
            theme:{
                color:"#686CFD",
            }
        };
        var pay=new window.Razorpay(options);
        pay.open()
    }
  return (
   <div>
        <div className='container mt-3'>
            <div className='row'>
                <div className='col-lg-6' style={{paddingTop:"100px",paddingLeft:"50px"}}>
                    <h1> Pay Order Bill Amount </h1>
                    <button onClick={paymentHandler} className='btn btn-primary' style={{width:"200px"}}>Pay Now</button>
                </div>

                <div className='col-lg-6'>
                    <p> <img src={pay} style={{width:"100%",height:"400px"}} alt='not found' /></p>
                </div>
            </div>
        </div>
    </div> 
  )
}

export default Paybill

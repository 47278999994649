import React, { useState } from 'react'
import axios from 'axios'

const Payment2 = () => {
    const initialValues={order_id:'',amount:'',paid_date:''}
    const [formValues, setFormValues] = useState(initialValues)

    const handleChange= (e) => {
        const{name,value} = e.target
        setFormValues({...formValues,[name]: value})
    }

    const formPayment=(e) => {
        e.preventDefault();
        axios.post("https://mern.digitalaisoft.com/pay2",{
        PaymentData:formValues
    }).then((response) => {
        console.log(response)
        alert("inserted successfully")
    })

    .catch(error => {
        console.log(error)
    })
}
  return (
    <div>
      <div className='container'>
        <div className='row'>
            <div className='col-lg-6 p-3 m-3 border border-success shadow'>
              <h1 className='text-success mt-3'>Payment</h1>
              <div className='divider py-1 bg-secondary shadow'></div>
              <form onSubmit={formPayment}>
                <div className='mt-3 mb-3'>
                    <label>ORDER_ID</label>
                    <input type='text' name='order_id' className='form-control border-success' value={formValues.order_id} onChange={handleChange} required/>
                </div>

                <div className='mt-3 mb-3'>
                    <label>AMOUNT</label>
                    <input type='text' name='amount' className='form-control border-success' value={formValues.amount} onChange={handleChange} required/>
                </div>

                <div className='mt-3 mb-3'>
                    <label>PAID_DATE</label>
                    <input type='date' name='paid_date' className='form-control border-success' value={formValues.paid_date} onChange={handleChange} required/>
                </div>

                <p className='text-center'><button className='btn btn-success' type='submit'>SUBMIT</button></p>
              </form>
            </div>
        </div>
      </div>
    </div>
  )}

export default Payment2

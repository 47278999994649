import React, { useState } from 'react';
import axios from 'axios';

const uid=localStorage.getItem('user')
const ResetPassword = () => {
  const [newpass,setNewpass]= useState('')
  const [confirmpass,setConfirmpass]=useState('')

  const handleChange=(e)=>{
    setNewpass(e.target.value);
  }
  const ResethandleChange =(e)=>{
    setConfirmpass(e.target.value)
  }
  const ResetFormSubmit=(e)=>{
    e.preventDefault();
    if(newpass===confirmpass)
    {
      axios.post("https://mern.digitalaisoft.com/resetpass",{
        newpass:newpass,
        confirmpass:confirmpass,
        uid:uid
      }).then((response)=>{
        window.location="http://myreact.digitalaisoft.com/login/"
      })
      .catch(error => {
        console.log(error)
      })
    }
    else{
      alert("New Password and Confirm Password must be same")
    }
  }
  return (
    <div>
      <div className='card w-500'>
        <div className='card-head'>
            <h1>Reset Password</h1>
        </div>
        <div className='card-body'>
            <form onSubmit={ResetFormSubmit}>
                <div className='mt-3 mb-3'>
                   <input type='text'  value={newpass} name='newpass' className='form-control' onChange={handleChange} placeholder='enter new password' required/> 
                </div>
                <div className='mt-3 mb-3'>
                  <input type='text' value={confirmpass} name='confirmpass' className='form-control' onChange={ResethandleChange} placeholder='enter confirm password' required/>
                </div>
                <input type='submit' className='btn btn-success' value='Reset Password'/>
            </form>
        </div>
      </div>
    </div>
  )
}


export default ResetPassword

import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom'
import './Navbar.css'

const utype=localStorage.getItem('log')
const uid=localStorage.getItem('user');
console.log(uid)

const Navbar = () => {
    // const location = useLocation();
    // const utype=localStorage.getItem('log');

    const [CategoryList, setCategoryList] = useState([])
    //const [CartCount, setCartCount] = useState(0)

    useEffect(() => {
        getCategory();
    },[]);

    const getCategory = async() => {
        const result = await axios.get(`https://mern.digitalaisoft.com/viewcat`);
        setCategoryList(result.data);
        //console.log(result.data);
    }

    const logout=()=>{
        localStorage.clear()
        window.location = `http://myreact.digitalaisoft.com/login`;
    }

    if(utype==="user")
    {

  return (
    <div>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-4 bg-dark text-light'>
                    <h5 className='p-3'><span style={{color:"#ff6868", fontSize:"28px"}}>F</span>INETECH</h5>
                </div>
                <div className='col-lg-8 bg-dark text-light'>
                    <nav className='navbar bg-dark navbar-expand-lg text-center'>
                        
                        <ul className='navbar-nav'>
                            <li className='nav-item'>
                                <a  href='/' className='nav-link  text-light  mt-1'>Home</a>
                            </li>
                            
                            <li className='nav-item'>
                                <a className='nav-link dropdown-toggle  mt-1 text-light' href='/userhome' role='button' data-bs-toggle="dropdown">Category</a>
                                <ul className='dropdown-menu'>
                                    {
                                        CategoryList.map((cat,index) => {
                                            let loc ="http://myreact.digitalaisoft.com/catwise/"+cat.category_name;
                                            return(<li key={cat.id}><a className='dropdown-item ' href={loc}>{cat.category_name}</a></li>)
                                        })
                                    }
                                </ul>
                            </li>
                            <li className='nav-item'>
                                <a href='/myorders' className='nav-link  text-light  mt-1'>My orders</a>
                            </li>
                            <li className='nav-item'>
                                <a href='/mycart' className='nav-link  text-light  mt-1'>My cart</a>
                            </li>

                            <li className='nav-item'>
                                <a  href='/newfeed' className='nav-link text-light   mt-1'>Feedback</a>
                            </li>

                            <li className='nav-item mx-3'><button className='btn btn-danger mt-2' onClick={logout}>logout</button></li>

                            <i className='fa fa-delete text-light'></i>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  )
}


else if(utype==="admin")
{
    return(
        <div>
                <div className='container-fluid pt-3 text-light' style={{backgroundColor:"#32012F"}} >
                    <div className='row'>

                        <div className='col-lg-4' style={{paddingLeft:"20px",backgroundColor:"#32012F"}}>
                            <h2 id='name1'>ADMIN</h2>
                        </div>

                        <div className='col-lg-4'>
                        <p><h2 className='text-center' id='name'>FINETECH</h2></p>
                        </div>

                        <div className='col-lg-2 pt-1' style={{paddingLeft:"100px"}}>
                            <h3 id='name1'>REVIEWS</h3>
                        </div>

                        <div className='col-lg-2'>
                            <p><button className='btn btn-danger' onClick={logout}>LOGOUT</button></p>
                        </div>
                    </div>
                </div>
        </div>
    )
}
else{
    return(
        <div>
        <div className='container-fluid '>
            <div className='row'>
                <div className='col-lg-4' style={{backgroundColor:"#2f1d37" ,color:"white"}}>
                <h5 className='p-3'><span style={{color:"#ff6868", fontSize:"28px"}}>F</span>INETECH</h5>
                </div>
                <div className='col-lg-8 px-5' style={{backgroundColor:"#2f1d37"}}>
                    <nav className='navbar  navbar-expand-lg text-center'>       
                        
                        <ul className='navbar-nav'>

                                <li className='nav-item mt-1'>
                                    <a href='/' className='nav-link' id='under'>Home</a>
                                </li>

                                <li className='nav-item  mt-1'>
                                    <a href='about' className='nav-link' id='under'>About</a>
                                </li>

                                <li className='nav-item  mt-1'>
                                    <a href='contact'  className='nav-link' id='under'>Contact</a>
                                </li>

                                <li className='nav-item t mt-1'>
                                    <a href='login'  className='nav-link' id='under'>Login</a>
                                </li>

                                <li className='nav-item  mt-2 mx-3'>
                                    <a href='reg'><button className='btn btn-danger'>Signup</button></a>
                                </li>
                                <i className='fa fa-delete text-dark '></i>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  )
}
}





export default Navbar


import React, { useEffect, useState } from 'react'
import axios from 'axios'
// import Myorders from './Myorders'
// import bg4 from '../Assets/bg4.jpg'
let orderamount='0'

const Mycart = () => {
    const[MycartData,setMycartData] = useState([])
    useEffect(() => {
        getMycart();
    })
 
    const getMycart = async() => { 
        const result = await axios.get(`https://mern.digitalaisoft.com/mycart`) 
    setMycartData(result.data) 
    } 
 
    const DeleteMycart=id => { 
        axios.delete(`https://mern.digitalaisoft.com/${id}`) 
        .then(response => { 
            getMycart() 
             
        }) 
    } 
 
  return (
    <div>
    {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1500, height:700,backgroundSize:'cover'}}> */}
       <div className='container mt-4'>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th className='bg-info text-center'>ID</th>
                    <th className='bg-info text-center'>USER_ID</th>
                    <th className='bg-info text-center'>PID</th>
                    <th className='bg-info text-center'>PRICE</th>
                    <th className='bg-info text-center'>TOTAL</th>
                    <th className='bg-info text-center'>ORDER_DATE</th>
                    <th className='bg-info text-center'>ORDER_TIME</th>
                    <th className='bg-info text-center'>ORDER_STATUS</th>
                    <th className='bg-info text-center'>PAYMENT_STATUS</th>
                    <th className='bg-info text-center'>Action</th>
               </tr>
            </thead>
            <tbody>
                {
                    MycartData.map((data,index) => {
                        return(<tr key={data.id}>
                            <td className='text-center'>{index+1}</td>
                            <td className='text-center'>{data.user_id}</td>
                            <td className='text-center'>{data.pid}</td>
                            <td className='text-center'>{data.price}</td>
                            <td className='text-center'>{data.total}</td>
                            <td className='text-center'>{data.order_date}</td>
                            <td className='text-center'>{data.order_time}</td>
                            <td className='text-danger text-center'>{data.order_status}</td>
                            <td className='text-danger text-center'>{data.payment_status}</td>    
                            <td className='text-center'><i className='fa fa-trash fs-3 px-3 text-danger' onClick={() => DeleteMycart(data.id)}></i></td>
                        </tr>)
                    })
                }
            </tbody>
            <div>
                <p>total amount : - ₹{orderamount=MycartData.reduce((total, item)=>total+(item.total),0)}</p>
            </div>
        </table>
        <p><button className='btn btn-success fs-5 fw-bold'><a href={`paybill_next/${orderamount}`} style={{textDecoration:"none",color:"white"}}>Place Order</a></button></p>
      </div>
      
    </div>
  )
}
export default Mycart

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ecommerce.css'
import { useParams } from 'react-router-dom';
const uid=localStorage.getItem('user')

const Newcatewise = () => {
    const { cat } = useParams();
    const [Newcatewisedata, setCatwiseData] = useState([]);
    const [error, setError] = useState(null); // State to store error

    useEffect(() => {
        getNewCatwiseProducts();
    })

    const getNewCatwiseProducts = async() => {
        const result = await axios.get(`https://mern.digitalaisoft.com/cat_wise/${cat}`);
        // setProductList(result.data);
        console.log(result.data);
    };

    useEffect(() => {
        const getNewCatwise = async () => {
            try {
                const result = await axios.get(`http://localhost:3001/cat_wise/${cat}`);
                setCatwiseData(result.data);
                setError(null); // Clear error if data fetched successfully
            } catch (error) {
                console.error('Error fetching category-wise data:', error);
                setError(error); // Set error state with Axios error
            }
        };

        getNewCatwise(); // Call the function inside useEffect

        // Specify dependencies to avoid unnecessary re-fetching
    }, [cat]); // Add 'cat' to the dependency array

    const AddCart= id => {
        //e.preventDefault();
        //alert(id)
        axios.post(`https://mern.digitalaisoft.com/${id}/${uid}`,{
        }).then((response) => {
            console.log(response);
            alert("Add To Cart Successfully")
            window.location="http://myreact.digitalaisoft.com/userhome"
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
        <div>
            <div className='container-fluid mt-2'>
                <div className='row'>
                    {error ? (
                        <div className='text-danger'>Error fetching data: {error.message}</div>
                    ) : (
                        Newcatewisedata.map((data, index) => {
                            return(
                                   <div className='col-lg-4 border' id='catwise'>
                                        <p key={data.id}></p>
                                        <input type='hidden'
                                         value={index+1}/>  
                                        <h4>{data.product_name}</h4>                                          
                                        <p className='text-center border'><img src={`../upload/${data.image}`} alt='unavailable' width={300} height={300}></img></p>
                                        <h4>&#8377;{data.price}</h4>
                                        <p>{data.description}</p>
                                        <p><button className='btn btn-info  fw-bold text-dark '  onClick={() => AddCart(data.id)}>Add Cart</button>
                                        <button className='btn btn-success fw-bold mx-2' ><a href={`/buyorder/${data.id}`} style={{textDecoration:"none",color:"white"}}>Buy</a></button>
                                        </p>
                                    </div>
                            )
                        })
                    )}
                </div>
            </div>
        </div>
    );
};

export default Newcatewise;

import React, { useEffect, useState } from 'react'
import axios from 'axios'
// import bg4 from '../Assets/bg4.jpg'

const NewProduct = () => {

	const [CatList, setCatList] = useState([])
	//const utype=localStorage.getItem('log')
	useEffect(() => {
		getCategory();
	}, []);

	const getCategory = async() => {
		const result = await axios.get("https://mern.digitalaisoft.com/viewcat");
		setCatList(result.data);
		console.log(result.data)
	};

	const initialValues ={category_name:"",product_name:"",uom:"",qty:"",price:"",description:"",stock:""}
	const [formValues,setFormValues] = useState(initialValues)
	const [file,setFile] = useState("")

	const handleChange = (e) => {
		const {name,value}= e.target
		setFormValues({ ...formValues,[name]:value});
	}

	const setImgFile = (e) =>
		{
		   // console.log(e.target.files[0])
			setFile(e.target.files[0])
		}
	
		const submitProduct = (e) => {
			e.preventDefault();
			var formData = new FormData();
			formData.append("file",file)
			formData.append("category_name",formValues.category_name)
			formData.append("product_name",formValues.product_name)
			formData.append("uom",formValues.uom)
			formData.append("qty",formValues.qty)
			formData.append("price",formValues.price)
			formData.append("description",formValues.description)
			formData.append("stock",formValues.stock)
			console.log(...formData)
			const config = {
				headers:
				{
					"Content-Type":"multipart/form-data"
				}    
			 }
			axios.post("http://localhost:3001/newpro",
			formData,config
			).then((response)=>{
			  console.log(response);
			  alert("Added Successfully");
			  window.location='http://myreact.digitalaisoft.com/newpro/'
			})
			.catch(error => {
				console.log(error)
			})

			axios.get('/api/resource')
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
		} 

  return (
	<div>
    {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1520, height:850,backgroundSize:'cover',}}> */}
      <div className='container-fluid'>
		<div className='row'>
			<div className='col-lg-3'></div>
			<div className='col-lg-6 text-cenrter shadow shadow-2 m-3 border border-dark'>
				<h1 className=' mt-3 text-center'>Product</h1>
				<hr></hr>
				<form onSubmit={submitProduct}>
					<div className='mt-3 '>
						<label>CATEGORY_NAME</label>
						<select name='category_name' 
						className='form-control' 
						defaultValue={formValues.category_name} 
						onChange={handleChange}>

							<option>--select Category--</option>
							{
								CatList.map((cat,index) => {
									return(
										<option key={cat.id} value={cat.category_name}>{cat.category_name}</option>
									)
								})
							}
							
						</select>
						</div>

					<div className='mt-3 '>
						<label>PRODUCT_NAME</label>
						<input 
						type='text'
						name='product_name' 
						value={formValues.product_name} 
						onChange={handleChange} 
						className='form-control border border-success border-1  shadow shadow-2' 
						required/>
					</div>

					<div className='mt-3'>
						<label>UOM</label>
						<input
						type='text' 
						name='uom' value={formValues.uom} 
						onChange={handleChange} 
						className='form-control border border-success border-1  shadow shadow-2' 
						required/>
					</div>

					<div className='mt-3 '>
						<label>QUANTITY</label>
						<input 
						type='text'
						name='qty'
						value={formValues.qty}
						onChange={handleChange} 
						className='form-control border border-success border-1  shadow shadow-2' 
						required/>
					</div>

					<div className='mt-3 '>
						<label>PRICE</label>
						<input
						type='text'
						name='price' 
						value={formValues.price}
						onChange={handleChange}
						className='form-control border border-success border-1  shadow shadow-2' 
						required/>
					</div>

					<div className='mt-3 '>
						<label>DESCRIPTION</label>
						<input 
						type='text'
						name='description' 
						value={formValues.description} 
						onChange={handleChange} 
						className='form-control border border-success border-1  shadow shadow-2' 
						required/>
					</div>

					<div className='mt-3'>
						<label>STOCK</label>
						<input
						type='text'
					    name='stock'
						value={formValues.stock}
						onChange={handleChange}
						className='form-control border border-success border-1  shadow shadow-2'
						required/>
					</div>

					<div className='mt-3 '>
						<label>IMAGE</label>
						<input 
						type='file'
						name='file' 
						onChange={setImgFile}
						className='form-control border border-success border-1  shadow shadow-2'
						required/>
					</div>

					<p className='text-center mt-2'>
					<button className='btn btn-success' 
					type='submit'>SUBMIT</button></p>
				</form>
			</div>
		</div>
	  </div>
    </div>
  )
}

export default NewProduct

import axios from 'axios'
import React, { useEffect, useState } from 'react'

const ViewProduct = () => {
  const[ProductData,setProductData] = useState([])
  useEffect(() => {
    getProduct();
  })

  const getProduct = async() => {
    const result = await axios.get(`https://mern.digitalaisoft.com/viewpro`)
    setProductData(result.data)
  }

  const DeleteProduct = id => {
    axios.delete(`https://mern.digitalaisoft.com/viewpro/${id}`)
    .then(response => {
      getProduct()
    })
  }
  return (
    <div>
      <div className='container'>
        <h4 className='text-center'>Category</h4>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>category_name</th>
                    <th>product_name</th>
                    <th>uom</th>
                    <th>qty</th>
                    <th>price</th>
                    <th>description</th>
                    <th>stock</th>
                    <th>image</th>
                    <th>Action</th>
               </tr>
            </thead>
            <tbody>
                {
                    ProductData.map((data,index) => {
                        return(<tr key={data.id}>
                            <td>{index+1}</td>
                            <td>{data.category_name}</td>
                            <td>{data.product_name}</td>
                            <td>{data.uom}</td>
                            <td>{data.qty}</td>
                            <td>{data.price}</td>
                            <td>{data.description}</td>
                            <td>{data.stock}</td>
                            <td>{data.image}</td>
                            <td><button className='btn btn-danger' onClick={() => DeleteProduct(data.id)}>delete</button></td>
                        </tr>)
                    })
                }
            </tbody>
        </table>
      </div>
    </div>
  )
}

export default ViewProduct

import React, { useState } from 'react'
import axios from 'axios'
// import bg4 from '../Assets/bg4.jpg'

const Otp = () => {
    const [otp,setOtp] =useState('')
    const [status,setStatus]=useState('')

    const handleChange =(e)=> {
        setOtp(e.target.value)
        console.log(e.target.value)
    }
    const SubmitOtp=(e)=>{
        e.preventDefault();
        axios.post("https://mern.digitalaisoft.com/otp",{
            otp:otp
        }).then((response)=>{
            console.log(response)
            if(response.data.length>0)
            {
                window.location="http://myreact.digitalaisoft.com/resetpass/"
            }
            else{
                setStatus('Sorry! Invalid Otp')
            }
        })
        .catch(error => {
            console.log(error)
        })
    }
  return (
    <div>
    {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1500, height:700,backgroundSize:'cover',}}> */}
      <div className='container mt-2'>
        <div className='row'>
            <div className='col-lg-3'></div>
            <div className='col-lg-6'>
              <h1 className='text-center '>ONE TIME PASSWORD</h1>
              <form onSubmit={SubmitOtp}>
                        <div className='mb-3 mt-3'>
                            <input type='number' value={otp} name='otp' className='form-control' placeholder='Enter your OTP' onChange={handleChange} required/>
                        </div>
                        <input type='submit' className='btn btn-success text-center' value='Verify Otp'/>
                    </form>
                    <p className='text-danger text-center'>{status}</p>
            <div className='col-lg-4'></div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Otp

import React,{useState} from 'react'
import axios from 'axios'
// import bg2 from '../Assets/bg2.jpg'

const Registration = () => {
    const initialValues = {Fname:'',Lname:'',Dob:'',Gender:'',Pincode:'',Address:'',Email:'',Mobile_no:''}
	const [formValues,setFormValues] = useState(initialValues)

    const handleChange = (e) =>{
		const {name,value}=e.target
		setFormValues({...formValues,[name]:value})
	}
	const FormSignUp=(e)=> {
		e.preventDefault();
	axios.post("https://mern.digitalaisoft.com/reg",{
		signupdata:formValues
	}).then((response) => {
		console.log(response)
		alert("thankyou for Registration")
		window.location="http://myreact.digitalaisoft.com/login"
	})
	.catch(error => {
		console.log(error)
	})
}
  return (
    <div>
       {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg2})`, width:1500, height:1000,backgroundSize:'cover'}}> */}
      <div className='container-fluid'>
        <div className='row mt-3 p-3'>
			<div className='col-lg-6 shadow shadow-2 p-5 border'>
        
			<h1 className='text-success mt-3'>Create An Account</h1>
			<div class="divider py-1 bg-secondary  shadow"></div>
			<form onSubmit={FormSignUp}>
				<div className='mt-3 mb-3'>
				<label>First Name :</label>
				<input style={{input:'focus'}} type='text' name='Fname'value={formValues.Fname} className='form-control border border-success border-1 ' onChange={handleChange} required></input>
				</div>

                <div className='mt-3 mb-3'>
				<label>Last Name :</label>
				<input type='text' name='Lname' value={formValues.Lname}className='form-control border border-success border-1 ' onChange={handleChange} required></input>
				</div>

				<div className='mt-3 mb-3'>
				<label>DOB :</label>
				<input type='date'  name='Dob' value={formValues.Dob}className='form-control border border-success border-1 ' onChange={handleChange} required></input>
				</div>

                <div className='mt-3 mb-3'>
				<label className='m-2'>GENDER :</label>
				<p>MALE <input type='radio' name='Gender' onChange={handleChange} value="male" Checked={formValues==='male'}/> 
				FEMALE <input type='radio' name='Gender' value="female" Checked={formValues==="female"} onChange={handleChange} required/></p>
                </div>

				<div className='mt-3 mb-3'>
				<label>PINCODE :</label>
				<input type='text' name='Pincode' value={formValues.Pincode}className='form-control border border-success border-1 ' onChange={handleChange} required></input>
				</div>

				<div className='mt-3 mb-3'>
				<label>ADDRESS :</label>
				<input type='text' name='Address' value={formValues.Address}className='form-control border border-success border-1 ' onChange={handleChange} required></input>
				</div>
                
				<div className='mt-3 mb-3'>
				<label>EMAIL :</label>
				<input type='text' name='Email' value={formValues.Email}className='form-control border border-success border-1 ' onChange={handleChange} required></input>
				</div>

				<div className='mt-3 mb-3'>

				<label>MOBILE NUMBER :</label>
				<input type='text' name='Mobile_no' value={formValues.Mobile_no}className='form-control border border-success border-1 ' onChange={handleChange} required></input>
				</div>

				<p className='text-center'><button className='btn btn-success'> Create An Account </button></p>
			</form>
		  </div>
		</div>
        </div> 
        </div>
    // </div>
  )
}

export default Registration

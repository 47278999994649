import axios from 'axios';
import React, { useEffect, useState } from 'react'

const ViewCategory = () => {
    const[CategoryData,setCategoryData] = useState([])
    useEffect(() => {
        getCategory();
    })
    const getCategory = async() => {
        const result = await axios.get(`https://mern.digitalaisoft.com/viewcat`)
        setCategoryData(result.data)
    }
    const Deletecategory = id => {
        axios.delete(`https://mern.digitalaisoft.com/viewcat/${id}`)
        .then(response => {
            getCategory()
        })
    }

  return (
    <div>
      <div className='container'>
        <h4 className='text-center'>Category</h4>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>CATEGORY_NAME</th>
                    <th>Action</th>
               </tr>
            </thead>
            <tbody>
                {
                    CategoryData.map((data,index) => {
                        return(<tr key={data.id}>
                            <td>{index+1}</td>
                            <td>{data.category_name}</td>
                            <td><button className='btn btn-danger' onClick={() => Deletecategory(data.id)}>delete</button></td>
                        </tr>)
                    })
                }
            </tbody>
        </table>
      </div>
    </div>
  )
}

export default ViewCategory

import React,{useState} from 'react'
import axios from 'axios'
// import bg4 from '../Assets/bg4.jpg'

const Category = () => {
  const initialValues= {category_name:''}
  const [formValues, setFormValues] = useState(initialValues)

  const handleChange =(e) => {
    const {name, value} = e.target
    setFormValues({...formValues, [name] : value})
    console.log(formValues.category_name)
  }

  const formCategory =(e) => {
    e.preventDefault();
    axios.post("https://mern.digitalaisoft.com/cat" ,{
      CategoryData:formValues
    }).then((response) => {
      console.log(response)
      alert("inserted succesfully")
    })
    .catch(error => {
      console.log(error)
    }) 
  }
  return (
    <div>
   {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1500, height:700,backgroundSize:'cover',}}> */}
      <div className='container'>
        <div className='row'>
            <div className='col-lg-3'></div>
            <div className='col-lg-6 p-3 m-3 border rounded border-dark shadow'>
                <h3 className='text-center m-2 '>Category</h3>
                <div><hr className='text-dark'></hr></div>
                <form onSubmit={formCategory}>
                <div className='p-1 m-1'>
                    <label className='form-label'>Category name</label>
                    <input type='text' className='form-control border-success' name='category_name' value={formValues.category_name} onChange={handleChange} required/>
                </div>
                <div className='p-1 m-1'>
                  <p className='text-center'><button className='btn btn-success text-light' type='submit'>Submit</button></p>
                </div>
                </form>
            </div>
            <div className='col-lg-3'></div>
        </div>
      </div>
    </div>
  )
}

export default Category


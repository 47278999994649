import React from 'react'
// import bg4 from '../Assets/bg4.jpg'
const Contact = () => {
  return (
    <div>
      {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1500, height:700,backgroundSize:'cover',}}> */}
      <div className='container-fluid' style={{marginTop:"100px",marginBottom:"100px"}}>
        <div className='row'>

          <div className='col-lg-2'></div>

          <div className='col-lg-8 m-4'>
              <div className='row'>

                <div className='col-lg-5  border border-2 text-center '>
                  <h3 className='mt-2'>GET IN TOUCH :</h3>
                  <form>

                  <div className='mt-4 mb-4'>
                    <input type='text' className='form-control border border-dark' name='name' placeholder='name'></input>
                  </div>

                  <div className='mt-4 mb-4'>
                    <input type='email' className='form-control border border-dark' name='email' placeholder='email'></input>
                  </div>

                  <div className='mt-4 mb-4'>
                    <input type='text' className='form-control border border-dark' name='phone number' placeholder='phone number'></input>
                  </div>

                  <div className='mt-4 mb-4'>
                    <input type='text'  className='form-control border border-dark' name='message' placeholder='message'></input>
                  </div>

                  <div className='mt-4 mb-4'>
                    <button className='btn btn-primary'>SUBMIT</button>
                  </div>

                  </form>
                </div>

                <div className='col-lg-6  border border-2 px-1 mx-1  pt-2 bg-success text-light'>
                  <h3 className='text-center mt-1'>CONTACT US :</h3>
                  <form>

                  <div className='mt-5 mb-5 px-4'>
                    <p><b>ADDRESS :</b> 3793 Deeptimay Ports, New Lakshmidharberg, Maharashtra 518 557, India </p>
                  </div>

                  <div className='mt-5 mb-5 px-4'>
                    <p><b>PHONE :</b>  +91 1111 2222 33</p>
                  </div>

                  <div className='mt-5 mb-5 px-4'>
                    <p><b>EMAIL :</b> finetech@gmail.com</p>
                  </div>

                  <div className='mt-5 mb-5 px-4'>
                    <p><b>WEBSITE :</b>www.finetech.com</p>
                  </div>
                  
                  </form>
                </div>
                </div>

              </div>
          </div>

          {/* <div className='col-lg-2'></div> */}

        </div>
      </div>
    // </div>
  )
}

export default Contact

import axios from 'axios';
import React, { useEffect, useState } from 'react'

const AdminHome = () => {
    const[CustOrderData,setCustOrderData] = useState([])
    useEffect(() => {
        getOrder();
    })
    const getOrder = async() => {
        const result = await axios.get(`https://mern.digitalaisoft.com/adminhome`)
        setCustOrderData(result.data)
    }
    const DeleteOrder = id => {
        axios.delete(`https://mern.digitalaisoft.com/adminhome/${id}`)
        .then(response => {
            getOrder()
        })
    }
    
  return (
    <div>
        <div className='container-fluid'>
            <div className='row'>
            <div className='col-lg-2  shadow shadow-2 border text-center'  style={{height:"795px",backgroundColor:"#39012F"}}>
                        <nav className='navbar' style={{paddingLeft:"20px",backgroundColor:"#32012F",marginLeft:"20px"}} >
                        
                        <ul className='navbar-nav'>
                            <li className='nav-item'>
                                <a  href='/' className='nav-link mt-1 text-light'><i className='fa fa-home'> Home</i></a>
                            </li>
                            
                            <li className='nav-item'>
                                <p className='nav-link dropdown-toggle text-light mt-1' data-bs-toggle="dropdown">Category</p>
                                <ul className='dropdown-menu border border-light'  style={{backgroundColor:"#32012F"}}>
                                    <li>
                                        <a className='dropdown-item text-light'  style={{backgroundColor:"#32012F"}}  href='/cat'>ADD</a>
                                    </li>
                                    <li>
                                        <a className='dropdown-item text-light'  style={{backgroundColor:"#32012F"}} href='/viewcat'>VIEW</a>
                                    </li>

                                </ul>
                            </li>

                            <li className='nav-item'>
                                <p className='nav-link dropdown-toggle  mt-1 text-light' data-bs-toggle="dropdown">Product</p>
                                <ul className='dropdown-menu border border-light'  style={{backgroundColor:"#32012F"}}>
                                    <li>
                                        <a className='dropdown-item text-light'  style={{backgroundColor:"#32012F"}} href='/newpro'>ADD</a>
                                    </li>
                                    <li>
                                        <a className='dropdown-item text-light'  style={{backgroundColor:"#32012F"}} href='/viewpro'>VIEW</a>
                                    </li>

                                </ul>
                            </li>

                            <li className='nav-item'>
                                <a  href='/newfeed' className='nav-link mt-1 text-light'>Feedback</a>
                            </li>

                        </ul>
                    </nav>   
            </div>

            <div className='col-lg-9' style={{marginLeft:"50px"}}>
                <h4 className='text-center'>DASHBOARD</h4>
                <div className='row'>

                <div className='col-lg-2 shadow border  p-5 text-center mx-4'  style={{width:"250px"}}>
                    <i className='fa fa-user-o fs-2 text-info'></i>
                    <h4>2500</h4>
                    <h4>WELCOME</h4>
                </div>

                <div className='col-lg-2 shadow border  p-5 text-center mx-2'  style={{width:"250px"}}>
                    <i className='fa fa-clock-o fs-2 text-primary'></i>
                    <h4>123.50</h4>
                    <h4>Average Time</h4>
                </div>

                <div className='col-lg-2 shadow border p-5 text-center mx-2'  style={{width:"250px"}}>
                    <i className='fa fa-cloud fs-2 text-success'></i>
                    <h4>1.805</h4>
                    <h4>collections</h4>
                </div>
                
                <div className='col-lg-2 shadow border  p-5 text-center mx-2'  style={{width:"250px"}}>
                    <i className='fa fa-comment fs-2 text-danger'></i>
                    <h4>154</h4>
                    <h4>comments</h4>
                </div>
            </div>
                
        <div className='col-lg-12 mt-4 shadow text-center' style={{height:"375px" }}>

        <table className='table table-bordered table-striped  pt-3 table-#32012F'>
            <thead>
                <tr >
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>ID</th>
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>user id</th>
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>pid</th>
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>quantity</th>
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>Price</th>
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>Total</th>
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>order date</th>
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>Order status</th>
                    <th  style={{backgroundColor:"#32012F",color:'white'}}>Action</th>
               </tr>
            </thead>
            <tbody>
                {
                    CustOrderData.map((data,index) => {
                        return(<tr key={data.id}>
                            <td>{index+1}</td>
                            <td>{data.user_id}</td>
                            <td>{data.quantity}</td>
                            <td>{data.price}</td>
                            <td>{data.total}</td>
                            <td>{data.order_date}</td>
                            <td>{data.order_status}</td>
                            <td>{data.payment_status}</td>
                            <td><button className='btn btn-danger' onClick={() => DeleteOrder(data.id)}>delete</button></td>
                        </tr>)
                    })
                }
            </tbody>
        </table>
      </div>
      </div>
      </div>
        </div>

    </div>


  )
}

export default AdminHome

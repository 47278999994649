import React from 'react'
import './Navbar.css'

const Footer = () => {
  return (
    <div >
      <div className='bg-dark text-light text-center p-2 m-2'style={{clear:"both" , height:"150px"}}>
            <i className='fa fa-facebook'></i>
            <i className='fa fa-whatsapp'></i>
            <i className='fa fa-linkedin'></i>
            <i className='fa fa-youtube'></i>
            <h3>&copy; 2024 COPYRIGHT FINETECH.com</h3>
      </div>
    </div>
  )
}

export default Footer
  
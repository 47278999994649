import axios from 'axios'
import React, { useEffect, useState } from 'react'
//import bg4 from '../Assets/bg4.jpg'

const Myorders = () => {
    const[MyorderData,setMyorderData] = useState([])
    useEffect(() => {
        getMyorders()
    })
    
    const getMyorders = async() => {
        const result = await axios.get(`http://localhost:3001/myorders`)
        setMyorderData(result.data)
        console.log(result.data);
    }

  return (
    <div>
     {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1500, height:700,backgroundSize:'cover'}}> */}
      <div className='container mt-4'>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th className='bg-info text-center'>ID</th>
                    <th className='bg-info text-center'>USER_ID</th>
                    <th className='bg-info text-center'>PID</th>
                    <th className='bg-info text-center'>PRICE</th>
                    <th className='bg-info text-center'>TOTAL</th>
                    <th className='bg-info text-center'>ORDER_DATE</th>
                    <th className='bg-info text-center'>ORDER_TIME</th>
                    <th className='bg-info text-center'>ORDER_STATUS</th>
                    <th className='bg-info text-center'>PAYMENT_STATUS</th>
                    <th className='bg-info text-center'>Action</th>

               </tr>
            </thead>
            <tbody>
                {
                    MyorderData.map((data,index) => {
                        return(
                        <tr key={data.id}>
                            <td className='text-center'>{index+1}</td>
                            <td className='text-center'>{data.user_id}</td>
                            <td className='text-center'>{data.pid}</td>
                            <td className='text-center'>{data.price}</td>
                            <td className='text-center'>{data.total}</td>
                            <td className='text-center'>{data.order_date}</td>
                            <td className='text-center'>{data.order_time}</td>
                            <td className='text-success text-center'>{data.order_status}</td>
                            <td className='text-danger text-center'>{data.payment_status}</td>
                            <td><a href={`/paybill/${data.id}/${data.price}`}><button className='btn btn-primary'>Paynow</button></a></td>
                        </tr>)
                    })
                }
            </tbody>
        </table>
      </div>
    </div>
  )
}

export default Myorders

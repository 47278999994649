import React,{useState} from 'react';
import axios from 'axios';
// import bg4 from '../Assets/bg4.jpg'


const NewwFeedback = () => {
    const initialValues = {user_id:'',about_products:'',about_services:'',comments:''}
    const [formValues,setFormValues] = useState(initialValues)
    const handleChange = (e) =>{
        const {name,value}= e.target
        setFormValues({...formValues,[name]: value}) 
        //console.log(formValues)
    }
    const formFeedback= (e) => {
        e.preventDefault();
        axios.post("https://mern.digitalaisoft.com/newfeed" ,{
            FeedbackData:formValues
          }).then((Response) => {
            console.log(Response)
            alert("Feedback added successfully")
          })
          .catch(error => {
            console.log(error)
          })
    }

  return (
    <div>
    {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1500, height:700,backgroundSize:'cover'}}> */}
      <div className='container'>
        <div className='row'>
            <div className='col-lg-3'></div>
            <div className='col-lg-6 p-3 m-3 border shadow border border-dark'>
                <h5 className='text-center m-2'><strong>Feedback</strong></h5>
                <div className='text-dark'><hr></hr></div>
                <form onSubmit={formFeedback}>
                <div className='p-1 m-1'>
                    <label className='form-label'>User id</label>
                    <input type='text' className='form-control border border-success' value={formValues.user_id} name='user_id' onChange={handleChange} required/>
                </div>
                <div className='p-1 m-1'>
                    <label className='form-label'>About product</label>
                    <input type='text' className='form-control border border-success' value={formValues.about_products} name='about_products' onChange={handleChange} required/>
                </div>
                <div className='p-1 m-1'>
                    <label className='form-label'>About service</label>
                    <input type='text' className='form-control border border-success' value={formValues.about_services} name='about_services' onChange={handleChange} required/>
                </div>
                <div className='p-1 m-1'>
                    <label className='form-label'>Comments</label>
                    <input type='text' className='form-control border border-success' value={formValues.comments} name='comments' onChange={handleChange} required/>
                </div>
                <div className='p-1 m-1 text-center'>
                    <button className='btn btn-success text-center' type='submit'>Submit</button>
                </div>
                </form>
            </div>
            <div className='col-lg-3'></div>
        </div>
      </div>

    </div>
  )
}

export default NewwFeedback

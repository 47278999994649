import React, { useState } from 'react'
import axios from 'axios'

const ForgotPassword = () => {
    const [email,setEmail]= useState('')
    const [status,setStatus]=useState('')

    const handleChange =(e) => {
        setEmail(e.target.value)
        console.log(e.target.value)
    }
    const SubmitForgotPassword =(e)=>{
        e.preventDefault();
        axios.post("https://mern.digitalaisoft.com/forgotpass", {
            email:email
        }).then((response)=> {
            console.log(response)
            if(response.data.length>0)
            {
                localStorage.setItem('user',email)
                window.location="http://localhost:3000/otp/"
            }
            else {
                setStatus('Invalid Email')
            }
        })
        .catch(error => {
            console.log(error)
        })
    }
  return (
    <div>
      <div className='container mt-2'>
        <div className='row'>
            <div className='card'>
                <div className='card-header'>
                    <h1>Forgot Password</h1>
                </div>
                <div className='card-body'>
                    <form onSubmit={SubmitForgotPassword}>
                        <div className='mb-3 mt-3'>
                            <input type='email' name='email' className='form-control' placeholder='Enter Your email' required onChange={handleChange}/>
                        </div>
                        <input type='submit' className='btn btn-success' value='Forgot Password'/>
                    </form>
                    <p className='text-danger'>{status}</p>
                </div>
                <p className='text-primary'><a href={`/login/`} className='nav-link'>Go Back</a></p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword

import React, { useEffect, useState } from 'react'
import axios from 'axios';

const ViewFeedback = () => {
    const[FeedbackData,setFeedbackData] = useState([])
    useEffect(() => {
        getfeedback();
    })
 
    const getfeedback = async() => {
        const result = await axios.get(`https://mern.digitalaisoft.com/viewfeed`)
        setFeedbackData(result.data)
    }
    const DeleteFeedback = id => {
        axios.delete(`https://mern.digitalaisoft.com/viewfeed/${id}`)
        .then(response => {
            getfeedback()
        })
    }
  return (
    <div>
      <div className='container'>
        <h4 className='text-center'>Feedback</h4>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>User Id</th>
                    <th>About Products</th>
                    <th>About Services</th>
                    <th>Comments</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    FeedbackData.map((data,index) => {
                        return(<tr key={data.id}>
                            <td>{index+1}</td>
                            <td>{data.user_id}</td>
                            <td>{data.about_products}</td>
                            <td>{data.about_services}</td>
                            <td>{data.comments}</td>
                            <td><button className='btn btn-danger' onClick={() => DeleteFeedback(data.id)}>delete</button></td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
      </div>
    </div>
  )
}

export default ViewFeedback

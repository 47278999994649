// import logo from './logo.svg';
// import './App.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import Navbar from './components/Layouts/Navbar.js';
import Home from './components/Home.js';
import Aboutus from './components/Aboutus.js';
import Contact from './components/Contact.js';
import Footer from './components/Layouts/Footer.js';
import Category from './components/Category.js';
import ViewCategory from './components/ViewCategory.js';
import Payment2 from './components/Payment2.js';
import NewProduct from './components/NewProduct.js';
import NewwFeedback from './components/NewwFeedback.js';
import Registration from './components/Registration.js';
import ViewFeedback from './components/ViewFeedback.js';
import ViewProduct from './components/ViewProduct.js';
import ViewPayment from './components/ViewPayment.js';
import ViewRegistration from './components/ViewRegistration.js';
import NewLogin from './components/NewLogin.js';
import UserHome from './components/UserHome.js';
import AdminHome from './components/AdminHome.js';
import ForgotPassword from './components/ForgotPassword.js';
import Otp from './components/Otp.js';
import ResetPassword from './components/ResetPassword.js';
import Newcatewise from './components/Newcatewise.js';
import Buyorder from './components/Buyorder.js';
import Myorders from './components/Myorders.js';
import Mycart from './components/Mycart.js';
import Paybill from './components/Paybill.js';
import Paybillnext from './components/Paybillnext.js';

function App() {
  return (
    <Router>
    <div className="App">
    <Navbar></Navbar>
    <Switch>
      <Route exact path="/" component={Home}></Route>
      <Route exact path="/userhome" component={UserHome}></Route>
      <Route exact path="/adminhome" component={AdminHome}></Route>
      <Route exact path="/about" component={Aboutus}></Route>
      <Route exact path="/contact" component={Contact}></Route>
      <Route exact path="/cat" component={Category}></Route>
      <Route exact path="/login" component={NewLogin}></Route>
      <Route exact path="/reg" component={Registration}></Route>
      <Route exact path="/pay2" component={Payment2}></Route>
      <Route exact path="/viewpay" component={ViewPayment}></Route>
      <Route exact path="/newpro" component={NewProduct}></Route>
      <Route exact path="/viewpro" component={ViewProduct}></Route>
      <Route exact path="/newfeed" component={NewwFeedback}></Route>
      <Route exact path="/viewfeed" component={ViewFeedback}></Route>
      <Route exact path="/viewreg" component={ViewRegistration}></Route>
      <Route exact path="/viewcat" component={ViewCategory}></Route>
      <Route exact path="/cate" component={Category}></Route>
      <Route exact path="/forgotpass" component={ForgotPassword}></Route>
      <Route exact path="/resetpass" component={ResetPassword}></Route>
      <Route exact path="/catwise/:cat/" component={Newcatewise}></Route>
      <Route exact path="/paybill/:id/:price" component={Paybill}></Route>
      <Route exact path="/paybill_next/:price" component={Paybillnext}></Route>
      <Route exact path="/buyorder/:id" component={Buyorder}></Route>
      <Route exact path="/myorders" component={Myorders}></Route>
      <Route exact path="/mycart" component={Mycart}></Route>
      <Route exact path="/otp" component={Otp}></Route>
    </Switch>
    <Footer></Footer>
    </div>
    </Router>
  );
}

export default App;

import axios from 'axios'
import React, { useEffect, useState } from 'react'

const ViewPayment = () => {
    const[PaymentData,setPaymentData] = useState([])
    useEffect(() => {
        getPayment();
    })

    const getPayment = async() => {
        const result = await axios.get(`https://mern.digitalaisoft.com/viewpay`)
        setPaymentData(result.data)
    }

    const DeletePayment = id => {
        axios.delete(`https://mern.digitalaisoft.com/viewpay/${id}`)
        .then(response => {
            getPayment()
        })
    }
  return (
    <div>
      <div className='container'>
        <h4 className='text-center'>Category</h4>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Order Id</th>
                    <th>Amount</th>
                    <th>Paid Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                   PaymentData.map((data,index) => {
                    return(<tr key={data.id}>
                        <td>{index+1}</td>
                        <td>{data.order_id}</td>
                        <td>{data.amount}</td>
                        <td>{data.paid_date}</td>
                        <td><button className='btn btn-danger' onClick={() => DeletePayment(data.id)}>Delete</button></td>
                    </tr>)
                   })
                }
            </tbody>
        </table>
      </div>
    </div>
  )
}

export default ViewPayment

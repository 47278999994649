import React from 'react'
import company1 from "../Assets/company1.jpg"
import p1 from "../Assets/p1.jpg"
import './ecommerce.css'
 
const Aboutus = () => {
  return (
    <div>
          <div className='conatainer-fluid m-5'>

            <div className='row'>
              {/* <div className='col-lg-2'></div> */}


                <div className='col-lg-12'>
                  <div className='row'>

                  <div className='col-lg-3'>
                   <img src={p1} className='border border-dark' alt='about' width={280} height={250} id='p1'></img>
                  </div>

                  <div className='col-lg-8'>
                    <h4>As a homegrown e-commerce organization, we direct our efforts towards building a sustainable business while creating inclusive and impactful growth for all our stakeholders. We believe in transformative innovation and risk-taking. We don't shy away from failure. We learn from our experiences and strive towards enhanced customer experiences while bridging the gap between Bharat and India with digital commerce.</h4>
                  </div>
                  </div>
                </div>
                </div>

                <div className='conatainer-fluid m-5'>

  <div className='row'>
      <div className='col-lg-12'>
        <div className='row'>


        <div className='col-lg-8'>
          <h4>The Finetech Group is one of India’s leading digital ecommerce entities and includes group companies finetech, Myntra, finetech Wholesale, Finetech Health+, and Cleartrip.</h4>
          <h5>Started in 2007, Finetech has enabled millions of sellers, merchants, and small businesses to participate in India's digital commerce revolution. With a registered customer base of more than 500 million, Finetech marketplace offers over 150 million products across 80+ categories. Today, there are over 14 lakh sellers on the platform, including Shopsy sellers. With a focus on empowering and delighting every Indian by delivering value through technology and innovation, Finetech has created lakhs of jobs in the ecosystem while empowering generations of entrepreneurs and MSMEs. Finetech is known for pioneering services such as Cash on Delivery, No Cost EMI and easy returns, which are customer-centric innovations that have made online shopping more accessible and affordable for millions of Indians. In 2024, Finetech also introduced the Finetech UPI handle to further enhance its digital payment offerings for all customers and further India’s digital economy vision.</h5>
        </div>

        <div className='col-lg-4 border border-dark'>
         <img src={company1}  alt='about' width={434} height={360}></img>
        </div>
        </div>
      </div> 
    </div>
  </div>
</div>
</div>      
  )
}

export default Aboutus

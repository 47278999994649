import React, { useState } from 'react';
import axios from 'axios';
// import bg4 from '../Assets/bg4.jpg'
import { useParams } from 'react-router-dom';
const uid = localStorage.getItem('user');
console.log(uid)

const Buyorder = () => {
    const { id } = useParams();
    const [quantity, setQty] = useState('');

    const handleChange = (e) => {
        setQty(e.target.value);
    };

    const orderSubmit = (e) => {
        e.preventDefault()
        axios.post('https://mern.digitalaisoft.com/buyorder', {
            quantity:quantity ,
            uid: uid,
            id: id
        })
        .then((response) => {
            alert("Ordered successfully");
            window.location = 'http://myreact.digitalaisoft.com/myorders';
        })
        .catch(error => {
            console.error(error);
            alert("Error placing order. Please try again.");
        });
    };

    return (
        <div>
        {/* <div className='border border-2  p-3 shadow shadow-2' style={{backgroundImage: `url(${bg4})`, width:1500, height:700,backgroundSize:'cover'}}> */}
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-4'></div>
                <div className='col-lg-4 text-center mt-5 shadow border p-5'>
                    <form onSubmit={orderSubmit}>
                        <h3>ENTER QUANTITY</h3>
                        <input
                            type='number'
                            className='form-control mt-4'
                            name='quantity'
                            max={10}
                            min={1}
                            value={quantity}
                            required
                            onChange={handleChange}
                        />
                        <p><button type='submit' className='btn btn-primary mt-3'>SUBMIT</button></p>
                    </form>
                </div>
                <div className='col-lg-4'></div>
            </div>
        </div>
        </div>

    );
};

export default Buyorder;

import React from 'react'
import banner11 from "../Assets/banner11.jpg"
import banner12 from "../Assets/banner12.jpg"
import banner13 from "../Assets/banner13.jpg"
import dellvostro from "../Assets/dellvostro.jpg"
import iphone15a from "../Assets/iphone15a.jpg"
import airpods from "../Assets/airpods.jpg"
import './ecommerce.css'



const Home = () => {
  return (
    <div id='body'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-12' id='home'>
                    <h1 id='text'>Your Trusted Gadgets Device Store Is Here. </h1>
                    <h5 id='texta' className='mt-2'>83% Off Save Your 13% Limited Time Offer</h5>
                    <h5 id='text' className='mt-2'>Don't Miss It Grab Now.</h5>
                    <p id='btn'><button className='btn btn-primary '>Grab Here Now</button></p>
                </div>
            </div>
        </div>

<div className='container px-4 mt-2'>

<div id="slider" className="carousel slide" data-bs-ride="carousel">

     <div className="carousel-indicators">
        <button type="button" data-bs-target="#slider" data-bs-slide-to="0" class="active"></button>
        <button type="button" data-bs-target="#slider" data-bs-slide-to="1"></button>
        <button type="button" data-bs-target="#slider" data-bs-slide-to="2"></button>
        <button type="button" data-bs-target="#slider" data-bs-slide-to="3"></button>
     </div>


<div className="carousel-inner">
    <div className="carousel-item active">
        <img id='banner' src={banner11} alt="Not Found"  height={345}  className="d-block w-100"/>
    </div>

    <div className="carousel-item">
        <img id='banner' src={banner12}  alt="Not Found"  height={345}  className="d-block w-100"/>
    </div>
  
    <div className="carousel-item">
        <img id='banner' src={banner13} alt="Not Found"  height={345}  className="d-block w-100"/>
    </div>
</div>

<button className="carousel-control-prev" type="button" data-bs-target="#slider" data-bs-slide="prev">
    <span className="carousel-control-prev-icon"></span>
</button>

<button className="carousel-control-next" type="button" data-bs-target="#slider" data-bs-slide="next">
    <span className="carousel-control-next-icon"></span>
</button>
</div>
</div>


<div className='container-fluid mt-2'>
            <div className='row'>

                <div className='col-lg-3'>

                    <div className='border shadow' id='box' >
                        <img src={dellvostro} alt="Not Found" className='border' id='pro' width={300} height={250}/>
                        <p className='m-2'> NAME :- Dell Vostro 5620</p>
                        <p className='m-2'>PRICE :-  ₹89,699</p>
                    </div>

                </div>

                <div className='col-lg-3'   >

                    <div className='border shadow' id='box' >
                        <img src={iphone15a} alt="Not Found" className='border' id='pro' width={300} height={250}/>
                        <p className='m-2'>NAME :- I PHONE 15 PRO MAX</p>
                        <p className='m-2'>PRICE :- ₹ 1,51,999</p>
                    </div>

                </div>

                <div className='col-lg-3'   >

                    <div className='border shadow' id='box' >
                        <img src={airpods} alt="Not Found" className='border ' id='pro' width={300} height={250}/>
                        <p className='m-2'>NAME :-  4K SAMSUNG CRYSTAL 4K </p>
                        <p className='m-2'>PRICE :- ₹ 1,13,999</p>
                    </div>

                </div>

                <div className='col-lg-3'>

                    <div className='border shadow' id='box' >
                        <img src={dellvostro} alt="Not Found" className='border' id='pro' width={300} height={250}/>
                        <p className='m-2'> NAME :- Dell Vostro 5620</p>
                        <p className='m-2'>PRICE :-  ₹89,699</p>
                    </div>

                </div>
                <div className='col-lg-3'>

                    <div className='border shadow' id='box' >
                        <img src={dellvostro} alt="Not Found" className='border' id='pro' width={300} height={250}/>
                        <p className='m-2'> NAME :- Dell Vostro 5620</p>
                        <p className='m-2'>PRICE :-  ₹89,699</p>
                    </div>

                </div>
                <div className='col-lg-3'>

                    <div className='border shadow' id='box' >
                        <img src={dellvostro} alt="Not Found" className='border' id='pro' width={300} height={250}/>
                        <p className='m-2'> NAME :- Dell Vostro 5620</p>
                        <p className='m-2'>PRICE :-  ₹89,699</p>
                    </div>

                </div>
                <div className='col-lg-3'>

                    <div className='border shadow' id='box' >
                        <img src={dellvostro} alt="Not Found" className='border' id='pro' width={300} height={250}/>
                        <p className='m-2'> NAME :- Dell Vostro 5620</p>
                        <p className='m-2'>PRICE :-  ₹89,699</p>
                    </div>

                </div>
                <div className='col-lg-3'>

                    <div className='border shadow' id='box' >
                        <img src={dellvostro} alt="Not Found" className='border' id='pro' width={300} height={250}/>
                        <p className='m-2'> NAME :- Dell Vostro 5620</p>
                        <p className='m-2'>PRICE :-  ₹89,699</p>
                    </div>

                </div>

            </div>
        </div>

    </div>
  )
}

export default Home

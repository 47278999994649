import axios from 'axios';
import React, { useEffect, useState } from 'react'

const ViewRegistration = () => {
    const [signupdata, setSignupData] = useState([])
    useEffect( () => {
        getSignup();
    })

    const getSignup = async() => {
        const result = await axios.get(`https://mern.digitalaisoft.com/viewreg`);
        setSignupData(result.data)
    }

    const DeleteRegistration = id => {
        axios.delete(`https://mern.digitalaisoft.com/viewreg/${id}`)
            .then(response => {
                getSignup()
            })
    }

  return (
    <div>
      <div className='container'>
        <h4 className=' text-center'>REGISTRATION</h4>
        <table className='table table-bordered'>
            <thead>
                <tr className='table-success text-center'>
                    <th>ID</th>
                    <th>FIRSTNAME</th>
                    <th>LASTNAME</th>
                    <th>DOB</th>
                    <th>GENDER</th>
                    <th>PINCODE</th>
                    <th>ADDRESS</th>
                    <th>EMAIL</th>
                    <th>MOBILE NO</th>
                    <th>Action</th>  
                </tr>
            </thead>

            <tbody>
                {
                    signupdata.map((data,index) => {
                        return(<tr key={data.id}>
                            <td>{index+1}</td>
                            <td>{data.Fname}</td>
                            <td>{data.Lname}</td>
                            <td>{data.Dob}</td>
                            <td>{data.Gender}</td>
                            <td>{data.Pincode}</td>
                            <td>{data.Address}</td>
                            <td>{data.Email}</td>
                            <td>{data.Mobile_no}</td>
                            <td><button className='btn btn-danger' onClick={() => DeleteRegistration(data.id)}>DELETE</button></td>
                        </tr>)
                    })
                }
            </tbody>
        </table>

      </div>
    </div>
  )
}

export default ViewRegistration
